import 'jquery';
import AOS from 'aos';
import Chart from 'chart.js';

export const init = () => {
  AOS.init({
    useClassNames: true,
    initClassName: false,
    animatedClassName: 'animate__animated',
  });

  document.addEventListener('aos:in:super-duper-1', ({ detail }) => {
    console.log('animated out', detail);
  });

  const ctx = document.getElementById('pzChart');
  new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [10, 10, 10, 20, 50],
          backgroundColor: ['#EBEDF0', '#E5B3FF', '#D480FF', '#AA00FF', '#7C00BA'],
        },
      ],
      labels: ['未开始', '练习中', '初步了解', '熟悉', '精通'],
    },
    options: {
      Axes: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },

      responsive: true,
      legend: {
        display: false,
        position: 'top',
      },
      cutoutPercentage: 80,
      title: {
        display: false,
        text: 'Chart.js Doughnut Chart',
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  });
};
